import React from "react";
import cx from "classnames";
import { ControllerButton } from "components/ControllerButton";
import { ReactComponent as Clothe } from "icons/clothe.svg";

export function ClotheController(props: {
    disabled?: boolean;
    className?: string;
    setClotheIndex: (idx: number) => void;
    clothes: NonNullable<
        NonNullable<Queries.CharacterQuery["character"]>["frontmatter"]
    >["clothes"];
}) {
    const className = props.className || "flex flex-col items-center lg:block";
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <section
            className={className}
        >
            <div className="uppercase contents lg:flex lg:items-center lg:text-2xl font-bold">
                <Clothe width={36} height={36} className="lg:mr-2" />
                <span className="mb-2 lg:mb-0">Clothes</span>
            </div>
            <div className="contents lg:block space-y-2 lg:space-y-0 lg:space-x-4 lg:mt-11">
                {props.clothes?.map((clothe, idx) => (
                    <ControllerButton
                        disabled={props.disabled}
                        className={cx({
                            "animate-pulse cursor-not-allowed": props.disabled,
                        })}
                        key={clothe?.id}
                        onClick={() => props.setClotheIndex(idx)}
                    >
                        {idx + 1}
                    </ControllerButton>
                ))}
            </div>
        </section>
    );
}
