import "swiper/css";
import "swiper/css/pagination";
import React, { useMemo } from "react";
import cx from "classnames";
import { Link } from "gatsby-plugin-react-i18next";
import {
    GatsbyImage,
    getImage,
    ImageDataLike,
    IGatsbyImageData,
} from "gatsby-plugin-image";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "@react-hookz/web";
import { GradientHeading } from "components/GradientHeading";

type Props = {
    asMenu?: boolean;
    characters: Queries.CharactersQuery["characters"]["edges"];
    activeId?: Queries.CharactersQuery["characters"]["edges"][number]["node"]["id"];
};

//Group array items by size
function chunk(arr: Props["characters"], size: number) {
    const chunked_arr = [];
    let index = 0;
    while (index < arr.length) {
        chunked_arr.push(arr.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

function Heading(props: { asMenu?: boolean }) {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <GradientHeading
            className="mx-auto max-w-wide"
            as={props.asMenu ? "h2" : "h1"}
        >
            Character
        </GradientHeading>
    );
}

type ItemProps = {
    active?: boolean;
    node: Props["characters"][number]["node"];
};

function Item({ node, active }: ItemProps) {
    return (
        <li>
            <Link
                to={`/Characters/${node.frontmatter?.slug}`}
                className="grid grid-rows-6 md:h-full"
            >
                <div
                    className={cx(
                        "border-2 row-start-2 row-end-7 col-span-full",
                        active ? "bg-character-card" : "bg-black"
                    )}
                />
                <GatsbyImage
                    image={
                        getImage(
                            node?.frontmatter?.thumbnail as ImageDataLike
                        ) as IGatsbyImageData
                    }
                    alt={node?.frontmatter?.title ?? ""}
                    className="w-[82%] mt-auto row-span-full col-start-1 mb-0.5"
                />
                <div
                    className="flex flex-col justify-end items-end text-white font-bold pr-2 pb-2 col-span-full
                    row-span-full transform-gpu"
                >
                    <span className="text-2xl">{node?.frontmatter?.title}</span>
                    <span className="text-4xl lg:text-[40px] uppercase">
                        {node?.frontmatter?.slug}
                    </span>
                </div>
            </Link>
        </li>
    );
}

export const CharacterList: React.FC<Props> = ({
    characters,
    activeId,
    asMenu,
}) => {
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);
    const chunkedList = chunk(characters, isDesktop ? 16 : 4);
    const SwiperSlides = useMemo(
        () =>
            chunkedList.map((chunk, index) => (
                <SwiperSlide key={index}>
                    <ul className="grid gap-8 md:gap-4 md:gap-x-4 md:grid-cols-2 lg:grid-cols-4">
                        {chunk.map(({ node }) => (
                            <Item
                                key={node.id}
                                active={activeId === node?.id}
                                node={node}
                            />
                        ))}
                    </ul>
                </SwiperSlide>
            )),
        [activeId, chunkedList]
    );
    const getInitialSlide = () => {
        const index = chunkedList.findIndex(
            (chunk) =>
                chunk.find(({ node }) => node.id === activeId) !== undefined
        );
        return index === -1 ? 0 : index;
    };
    return (
        <div className="mb-24 lg:mb-72">
            <Heading asMenu={asMenu} />
            <div className="py-16 lg:py-20 px-10 mx-auto max-w-wide bg-gradient-100 lg:bg-gradient-200">
                <div className="mx-auto max-w-[1386px]">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={40}
                        pagination={paginationConfig}
                        initialSlide={getInitialSlide()}
                    >
                        {SwiperSlides}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};
const paginationConfig = {
    clickable: true,
    horizontalClass: "swiper-pagination-horizontal !static mt-3",
    bulletClass:
        "swiper-pagination-bullet !w-2 !h-2 !bg-purple-100 !opacity-100",
    bulletActiveClass: "swiper-pagination-bullet-active !bg-black",
};
