import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useMediaQuery } from "@react-hookz/web";

export function CharacterBackground(props: {
    node?: Queries.CharacterQuery["character"];
}) {
    const isMobile = !useMediaQuery("(min-width: 1024px)", true);
    const image = isMobile
        ? props.node?.frontmatter?.backgroundImageMobile?.childImageSharp?.gatsbyImageData
        : props.node?.frontmatter?.backgroundImage?.childImageSharp?.gatsbyImageData;

    return (
        <div className="-mx-5 lg:mx-0 lg:mt-20 col-span-full row-span-full -z-10 object-contain">
            {image && (
                <GatsbyImage
                    image={image}
                    alt="Background image"
                />
            )}
        </div>
    );
}
