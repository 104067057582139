import React from "react";
import { Warning, Props as WarningProps } from "components/Warning";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { useSoundWarning } from "hooks/useSoundWarning";
import { ReactComponent as Headphone } from "icons/headphone.svg";

type Props = Pick<WarningProps, "onCancel" | "onConfirm" | "open">;

export const SoundWarning: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const [ignore, setIgnore] = useSoundWarning();
    const handleIgnoreChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIgnore(e.target.checked);
    };

    return (
        <Warning
            type={t("modal.caution")}
            title={t("modal.sound_title")}
            confirm={t("modal.sound_confirm")}
            cancel={t("modal.sound_deny")}
            icon={<Headphone className="mx-auto h-24 w-24" />}
            description={
                <p>
                    <Trans
                        i18nKey="modal.sound_content"
                        ns="translation"
                        components={{
                            b: <br />,
                        }}
                    />
                </p>
            }
            {...props}
        >
            <label className="rounded bg-metal flex items-center justify-center text-white lg:text-xl p-3 col-span-full">
                <Trans
                    i18nKey="modal.no_warning_today"
                    ns="translation"
                    components={{
                        i: (
                            <input
                                className="form-checkbox rounded w-4 h-4 mr-2"
                                type="checkbox"
                                checked={ignore}
                                onChange={handleIgnoreChecked}
                            />
                        ),
                    }}
                />
            </label>
        </Warning>
    );
};
