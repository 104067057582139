import { useEffect, useState } from "react";

type Images = NonNullable<
    NonNullable<Queries.CharacterQuery["character"]>["frontmatter"]
>["clothes"];

const generateQueue = (
    assets: Images
): [Promise<HTMLImageElement>[], string] | void => {
    if (typeof window === "undefined") return;

    const randomStr = Math.random().toString(32).slice(2) + Date.now();
    window.preloadAssetsData = window.preloadAssetsData ?? {};
    window.preloadAssetsData[randomStr] = [];
    const caches = window.preloadAssetsData[randomStr];

    return [
        assets?.map(
            async (image) =>
                new Promise((resolve, _reject) => {
                    const img = new Image();
                    img.src = image?.publicURL ?? "";
                    img.onload = () => {
                        caches.push(img);
                        resolve(img);
                        img.onerror = () => resolve(img);
                    };
                })
        ) ?? [],
        randomStr,
    ];
};

export function usePreloadImages(images?: Images) {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!images) return;

        const queue = generateQueue(images);

        if (!queue) return;

        const [promises, randomStr] = queue;

        (async () => {
            await Promise.all(promises);
            setLoaded(true);
        })();

        return () => {
            delete window.preloadAssetsData?.[randomStr];
        };
    }, [images]);

    return loaded;
}
