import React, { useState } from "react";
import { useSoundWarning } from "hooks/useSoundWarning";
import { ControllerButton } from "components/ControllerButton";
import { SoundWarning } from "components/SoundWarning";
import _ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import cx from "classnames";

const Player = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export function PlayerButton(props: {
    warning?: boolean;
    url: string;
    handlePlay: (url: string) => void;
    children: React.ReactNode;
}) {
    const [ignore] = useSoundWarning();
    const [ready, setReady] = useState(false);
    const [open, setOpen] = useState(false);
    const handlePlay = () => props.handlePlay(props.url);
    const handleClick = () => {
        if (!props.warning || ignore) {
            handlePlay();
            return;
        }
        setOpen(true);
    };
    const handleConfirm = () => {
        setOpen(false);
        handlePlay();
    };
    const handleCancel = () => setOpen(false);
    const handleReady = () => setReady(true);

    return (
        <>
            <ControllerButton
                disabled={!ready}
                warning={props.warning}
                onClick={handleClick}
                className={cx({ "animate-pulse cursor-not-allowed": !ready })}
            >
                {props.children}
            </ControllerButton>
            <SoundWarning
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                open={open}
            />
            <Player
                muted
                onReady={handleReady}
                url={props.url}
                className="hidden"
            />
        </>
    );
}
