import React, { useState, useRef } from "react";
import _ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import { PlayerButton } from "components/PlayerButton";
import { ReactComponent as Voice } from "icons/voice.svg";

const Player = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export function VoiceController(props: {
    className?: string;
    character: Queries.CharacterQuery["character"];
}) {
    const [playing, setPlaying] = useState(false);
    const [currentVoice, setCurrentVoice] = useState<string | undefined>(
        undefined
    );
    const PlayerRef = useRef<_ReactPlayer>(null);
    const handlePlay = (url: string) => {
        setCurrentVoice(url);
        PlayerRef.current?.seekTo(0);
        setPlaying(true);
    };

    // noinspection AllyJsxHardcodedStringInspection
    return (
        <>
            {currentVoice && (
                <Player
                    ref={PlayerRef}
                    className="hidden"
                    url={currentVoice}
                    playing={playing}
                    config={{
                        file: {
                            forceAudio: true,
                        },
                    }}
                    onEnded={() => setPlaying(false)}
                />
            )}
            <section className={props.className}>
                <div className="uppercase text-2xl font-bold flex items-center">
                    <Voice width={36} height={36} className="mr-2" />
                    Voice
                </div>
                <div className="space-x-2 lg:space-x-4 mt-4 lg:mt-11">
                    {props.character?.frontmatter?.voices?.map(
                        (voice, idx) =>
                            voice?.publicURL && (
                                <PlayerButton
                                    key={voice?.id}
                                    url={voice?.publicURL}
                                    handlePlay={handlePlay}
                                >
                                    {idx + 1}
                                </PlayerButton>
                            )
                    )}
                    {props.character?.frontmatter?.sexyVoices?.map(
                        (sexyVoice) =>
                            sexyVoice?.publicURL && (
                                <PlayerButton
                                    warning
                                    key={sexyVoice?.id}
                                    url={sexyVoice?.publicURL}
                                    handlePlay={handlePlay}
                                >
                                    !
                                </PlayerButton>
                            )
                    )}
                </div>
            </section>
        </>
    );
}
