import React from "react";
import cx from "classnames";

export function ControllerButton({
    warning,
    className,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & { warning?: boolean }) {
    return (
        <button
            type="button"
            className={cx(
                "font-bold text-2xl lg:text-4xl text-white inline-flex items-center justify-center rounded-full",
                "w-[54px] h-[54px] lg:w-[75px] lg:h-[75px]",
                warning ? "bg-pink" : "bg-black",
                className
            )}
            {...props}
        />
    );
}
