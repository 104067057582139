import React, { Fragment, useState, useRef } from "react";
import { ReactComponent as Camera } from "icons/camera.svg";
import { ReactComponent as Close } from "icons/cross.svg";
import { Dialog, Transition } from "@headlessui/react";
import _ReactPlayer, { ReactPlayerProps } from "react-player/lazy";
import { PlayerButton } from "components/PlayerButton";

const Player = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export function HCGController(props: { className?: string; source: string }) {
    const [playing, setPlaying] = useState(false);
    const PlayerRef = useRef<_ReactPlayer>(null);
    const handlePlay = () => {
        PlayerRef.current?.seekTo(0);
        setTimeout(() => {
            setPlaying(true);
        }, 300);
    };
    const handleClose = () => setPlaying(false);

    // noinspection AllyJsxHardcodedStringInspection
    return (
        <>
            <section className={props.className}>
                <div className="uppercase text-2xl font-bold flex items-center">
                    <Camera width={36} height={36} className="mr-2" />
                    HCG
                </div>
                <div className="mt-4 lg:mt-11">
                    <PlayerButton
                        warning
                        handlePlay={handlePlay}
                        url={props.source}
                    >
                        !
                    </PlayerButton>
                </div>
            </section>
            <Transition appear show={playing} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-20"
                    onClose={handleClose}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/50 backdrop-blur-md" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-9 lg:p-14 text-center relative">
                            <Transition.Child
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[278px] landscape:max-w-[25vw] overflow-hidden align-middle aspect-hcg">
                                    <Player
                                        controls
                                        ref={PlayerRef}
                                        url={props.source}
                                        playing={playing}
                                        width="100%"
                                        height="100%"
                                    />
                                </Dialog.Panel>
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="absolute right-0 top-0 transform -translate-y-full md:translate-x-full"
                                >
                                    <Close className="w-8 h-8 lg:w-14 lg:h-14" />
                                </button>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
