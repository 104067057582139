import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import cx from "classnames";
import { useMediaQuery } from "@react-hookz/web";
import { isDMM } from "libs/dmm";
import { usePreloadImages } from "hooks/usePreloadImages";
import { Layout } from "components/Layout";
import { CharacterList } from "components/CharacterList";
import { StrokeHeading } from "components/StrokeHeading";
import { CharacterBackground } from "components/CharacterBackground";
import { ClotheController } from "components/ClotheController";
import { HCGController } from "components/HCGController";
import { VoiceController } from "components/VoiceController";
import { ComicController } from "components/ComicController";

function Heading(props: { name?: string | null }) {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <StrokeHeading>
            Character
            <br />
            {props.name}
        </StrokeHeading>
    );
}

export default function Character({ data }: PageProps<Queries.CharacterQuery>) {
    const { t } = useTranslation();
    const [clotheIndex, setClotheIndex] = useState(0);
    const isDesktop = useMediaQuery("(min-width: 1024px)", true);
    const birthday = data.character?.frontmatter?.birthday?.split("/") ?? [];
    const messages = data.character?.frontmatter?.message?.split("  \n");

    const clothesLoaded = usePreloadImages(
        data.character?.frontmatter?.clothes
    );

    const comicsAvailable = !!data.character?.frontmatter?.comics;

    return (
        <Layout>
            <div className="grid mb-36 overflow-hidden">
                {isDesktop && (
                    <CharacterBackground node={data.character} />
                )}
                <div className="px-5 col-span-full row-span-full">
                    <div className="flex md:justify-center">
                        <Heading name={data.character?.frontmatter?.slug} />
                    </div>
                    <div className="flex flex-col lg:flex-row lg:items-start xl:items-end mx-auto max-w-xs lg:max-w-closet lg:-mt-20">
                        {!isDesktop && (
                            <section className="font-bold my-4 -order-2">
                                {messages?.map((paragraph, idx) => (
                                    <p key={idx}>{paragraph}</p>
                                ))}
                            </section>
                        )}
                        <div className="relative mt-0 mb-3 lg:mb-6">
                            {!isDesktop && (
                                <div className="absolute -right-5 bottom-[15%] w-[calc(100%+2.5rem)] -z-10">
                                    <CharacterBackground node={data.character} />
                                </div>
                            )}
                            <section className="grid w-full max-w-[385px] lg:max-w-[500px] xl:max-w-[620px] relative -left-16 lg:left-0 lg:-mr-12 pointer-events-none">
                                {clothesLoaded
                                    ? data.character?.frontmatter?.clothes?.map(
                                          (clothe, idx) =>
                                              clothe?.publicURL && (
                                                  <img
                                                      className={cx(
                                                          "col-span-full row-span-full",
                                                          {
                                                              invisible:
                                                                  clotheIndex !==
                                                                  idx,
                                                          }
                                                      )}
                                                      key={clothe?.id}
                                                      alt="Character Photo"
                                                      src={clothe?.publicURL}
                                                  />
                                              )
                                      )
                                    : data.character?.frontmatter?.swiperImage
                                          ?.childImageSharp
                                          ?.gatsbyImageData && (
                                          <GatsbyImage
                                              alt="Character Photo"
                                              className="col-span-full row-span-full"
                                              image={
                                                  data.character?.frontmatter
                                                      ?.swiperImage
                                                      ?.childImageSharp
                                                      ?.gatsbyImageData
                                              }
                                          />
                                      )}
                            </section>
                            {!isDesktop && (
                                <div className="space-y-6 absolute right-0 top-1/2 transform -translate-y-1/2">
                                    <section className="inline-grid gap-3">
                                        {data.character?.frontmatter?.facePhotos?.map(
                                            (photo) =>
                                                photo?.childImageSharp && (
                                                    <GatsbyImage
                                                        key={photo?.id}
                                                        image={
                                                            photo
                                                                ?.childImageSharp
                                                                ?.gatsbyImageData
                                                        }
                                                        alt="Face photo"
                                                        className="w-20 h-20"
                                                    />
                                                )
                                        )}
                                    </section>
                                    <ClotheController
                                        disabled={!clothesLoaded}
                                        setClotheIndex={setClotheIndex}
                                        clothes={
                                            data.character?.frontmatter
                                                ?.clothes ?? []
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <div className="contents lg:block lg:max-w-[670px]">
                            <section className="font-bold -order-1 lg:flex lg:items-end lg:justify-between lg:pb-5">
                                <div className="contents lg:block">
                                    <h2 className="text-5xl lg:text-6.5xl">
                                        {data.character?.frontmatter?.title}
                                    </h2>
                                    <p className="text-3.5xl lg:text-4.5xl">
                                        CV:{data.character?.frontmatter?.cv}
                                    </p>
                                </div>
                                {isDesktop && (
                                    <section className="inline-grid gap-5 grid-cols-2">
                                        {data.character?.frontmatter?.facePhotos?.map(
                                            (photo) =>
                                                photo?.childImageSharp && (
                                                    <GatsbyImage
                                                        key={photo?.id}
                                                        image={
                                                            photo
                                                                ?.childImageSharp
                                                                ?.gatsbyImageData
                                                        }
                                                        alt="Face photo"
                                                        className="w-32 h-32"
                                                    />
                                                )
                                        )}
                                    </section>
                                )}
                            </section>
                            <section className="lg:text-2xl">
                                <dl className="border-y-2 py-3 px-1 flex space-x-4">
                                    <dt>{t("label.birthday")}</dt>
                                    <dd>{t("value.birthday", birthday.length === 2 ? birthday : ['?', '?'])}</dd>
                                    <dt className="!ml-8">
                                        {t("label.height")}
                                    </dt>
                                    <dd className="uppercase">
                                        {data.character?.frontmatter?.height || '?'}cm
                                    </dd>
                                </dl>
                                <div
                                    className="px-1 py-4 lg:py-12"
                                    dangerouslySetInnerHTML={{
                                        __html: data.character?.html ?? "",
                                    }}
                                />
                            </section>
                            {isDesktop && (
                                <section className="space-y-1 mt-8 mb-20">
                                    {messages?.map((paragraph, idx) => (
                                        <p
                                            key={idx}
                                            className="transform -skew-x-12"
                                        >
                                            <span className="text-white text-3.5xl bg-black px-2">
                                                {paragraph}
                                            </span>
                                        </p>
                                    ))}
                                </section>
                            )}
                            {isDesktop && (
                                <div className="space-y-4 xl:grid xl:grid-cols-12 xl:space-y-0 mb-12">
                                    <ClotheController
                                        className="xl:col-span-7"
                                        disabled={!clothesLoaded}
                                        setClotheIndex={setClotheIndex}
                                        clothes={
                                            data.character?.frontmatter?.clothes ??
                                            []
                                        }
                                    />
                                    {comicsAvailable && (
                                        <ComicController
                                            className="xl:col-span-7 xl:col-start-8"
                                            character={data.character}
                                        />
                                    )}
                                </div>
                            )}
                            <div className="grid space-y-4 xl:grid-cols-12 xl:space-y-0">
                                <VoiceController
                                    className="col-span-12 xl:col-span-7"
                                    character={data.character}
                                />
                                {data.character?.frontmatter?.hcg?.publicURL &&
                                    !isDMM && (
                                        <HCGController
                                            className="col-span-6 xl:col-span-5"
                                            source={
                                                data.character.frontmatter.hcg
                                                    .publicURL
                                            }
                                        />
                                    )}
                                {!isDesktop && comicsAvailable && (
                                        <ComicController
                                            className="col-span-6 xl:col-span-7 xl:col-start-8"
                                            character={data.character}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CharacterList
                asMenu
                characters={data.characters.edges}
                activeId={data.character?.id}
            />
        </Layout>
    );
}

export const query = graphql`
    query Character($language: String!, $frontmatter__slug: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ...Translation
                }
            }
        }
        characters: allMarkdownRemark(
            filter: {
                frontmatter: { type: { eq: "character" } }
                fields: { language: { eq: $language } }
            }
            sort: { fields: frontmatter___order }
        ) {
            edges {
                node {
                    ...CharacterSummary
                }
            }
        }
        character: markdownRemark(
            frontmatter: {
                type: { eq: "character" }
                slug: { eq: $frontmatter__slug }
            }
            fields: { language: { eq: $language } }
        ) {
            id
            html
            frontmatter {
                title
                area
                birthday
                cv
                height
                slug
                message
                swiperImage {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            quality: 90
                            sizes: "(max-width: 768px) 100vw, 571px"
                        )
                    }
                }
                clothes {
                    id
                    publicURL
                }
                facePhotos {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            quality: 90
                            sizes: "(max-width: 768px): 84px, 148px"
                        )
                    }
                }
                backgroundImage {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                        )
                    }
                }
                backgroundImageMobile {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                        )
                    }
                }
                voices {
                    id
                    publicURL
                }
                comics {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                        )
                    }
                }
                sexyVoices {
                    id
                    publicURL
                }
                hcg {
                    publicURL
                }
            }
        }
    }
`;

export { Head } from "components/GCSRewriteURL";
